import { useEffect, useState } from "react";
import { 
    ToastContainer, Toast, 
    Row, Col, FloatingLabel, 
    Form, Button 
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import Hearder from "./Header";

const ResetPassword = (props) => {
	const { urls, token } = props;
    const [email, setEmail] = useState("");
	const [passwords, setPasswords] = useState(["", ""]);
    //  Toast Variable
    const [toast, setToast] = useState({
        show: false,
        title: "", 
        content: "", 
        bg: "light", 
        "auto-hide": false, 
    });
	
    useEffect(() => {
        let options = {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({
                token: token
            })
        }

        fetch(urls['token-email'], options)
            .then(res => res.json())
            .then(json => {
                const { email, status_msg } = json;

                if(email !== "")
                    setEmail(email);
                
                setToast({
                    ...toast, 
                    show: true, 
                    title: "Token Verification", 
                    content: status_msg, 
                    bg: email? "success": "danger", 
                    "auto-hide": true, 
                });
            })
            .catch(err => {
                
                alert(err);
            })
    }, []);

    /*
        Password condition
    */
    const isPasswordLength = (password, min_length=6) => password.length >= min_length;
    const isPasswordSame = (password1, password2) => password1 === password2;
    const validPassword = (passwords) => 
        isPasswordSame(...passwords) && 
        isPasswordLength(passwords[0]) && 
        isPasswordLength(passwords[1]);
    
	const updatePassword = () => {
		if(!validPassword(passwords)) {
            setToast({
                ...toast, 
                show: true, 
                icon: faXmark, 
                title: "Input Password", 
                content: "Passsword does not meet the conditions.",
                "auto-hide": true, 
            });

            return;
        }
        
        let options = {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({ 
                token: token, 
                new_password: passwords[0], 
            })
        }

        fetch(urls['update-password'], options)
            .then(res => res.json())
            .then(json =>{
                const { verify_code, status_msg } = json;

                if(verify_code === 1){
                    setToast({
                        ...toast, 
                        show: true, 
                        title: "Update Password", 
                        content: status_msg, 
                        bg: "success", 
                        "auto-hide": true, 
                    });
                }
            })
            .catch(err => {
                console.error(err);
                console.log(`Unable request to backend.`);
                alert(`Unable request to backend.`);
            })
	}

	const WarningMessage = ({msg}) => <span className="ms-1 text-danger">{msg}</span>

	return (
    <>
        <Hearder title="Reset Password" show={true} />
        <ToastContainer className="mt-5" position="top-center">
            <Toast 
                className="text-light" 
                bg={toast.bg} 
                show={toast.show} 
                onClose={() => setToast({
                    ...toast, 
                    show: false, 
                })} 
                autohide={toast["auto-hide"]}
            >
                <Toast.Header>
                    <FontAwesomeIcon className="me-2" icon={ email? faCheck: faXmark } />
                    <strong className="me-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body>
                    {toast.content}
                </Toast.Body>
            </Toast>
        </ToastContainer>
		<div className="position-absolute top-50 start-50 translate-middle"
			style={{ minWidth: "350px" }}>
            <Row className="m-4">
                <Col>
                    <FloatingLabel controlId="email" 
                        label={<b>
                            <FontAwesomeIcon className="me-2" icon={faUser} />Email
                            <FontAwesomeIcon 
                                className={`ms-2 text-${ email? "success": "danger" }`} 
                                icon={ email? faCheck: faXmark } 
                            />
                        </b>}>
                        <Form.Control type="email" placeholder="Email ID" value={email} disabled />
                    </FloatingLabel>
                </Col>
            </Row>
			<Row className="m-4">
                <Col>
                    <FloatingLabel controlId="input_password" 
                        label={
							<b>
								<FontAwesomeIcon className="me-2" icon={faKey} />
								Password<span className="ms-1 text-danger">*</span>
							</b>
						}>
                        
                        <Form.Control type="password" placeholder="New Password" value={passwords[0]} 
							onChange={e => setPasswords([e.target.value, passwords[1]])} />
                    </FloatingLabel>
                </Col>
            </Row>
			<Row className="m-4">
                <Col>
                    <FloatingLabel controlId="confirm_password" 
                        label={
							<b>
								<FontAwesomeIcon className="me-2" icon={faKey} />
								Confirm Password<span className="ms-1 text-danger">*</span>
							</b>
						}>
                        
                        <Form.Control type="password" placeholder="Confirm Password" value={passwords[1]} 
							onChange={e => setPasswords([passwords[0], e.target.value])} />
                    </FloatingLabel>
					<div>{ 
                        /* check input passwords is valid or not */
						(!validPassword(passwords))? 
							<WarningMessage msg={<>
								{ 
                                    !isPasswordLength(passwords[0]) || !isPasswordLength(passwords[1])?
                                        <div>- Password must more than 6 length.</div>: null
                                }
                                {
                                    !isPasswordSame(...passwords)?
                                        <div>- Input Password must same.</div>: null
                                }
							</>} />: 
							null 
					}</div>
                </Col>
            </Row>

			<Row className="m-4">
				<Col>
					<Button variant="outline-primary w-100 mt-4"
						onClick={updatePassword} disabled={!validPassword(passwords)}
					>Update Password</Button>
				</Col>
			</Row>
		</div>
    </>
	);
}

export default ResetPassword;