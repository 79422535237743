import {
	BrowserRouter, Routes, Route, 
	useParams 
} from "react-router-dom";
//	Custom Component
import { getAPIUrls } from "./Api";
import Home from "./Home";
// import EditStatus from "./EditStatus";
import Information from "./Information";
import ResetPassword from "./ResetPassword";

const Service = (props) => {
	const { urls } = props;
	//	page & parameter, ex: "host/service?parameter=value"
	let { service } = useParams();
	let url_params = new URLSearchParams(window.location.search);
	let token = (url_params.get("token") || "");
	
	const getService = (service) => {
		switch(service){
			// case "edit-status":
			// 	return <EditStatus title="Status List" />;
			case "information":
				return <Information title="" urls={urls} />;
			case "reset-password":
				return <ResetPassword 
					token={token} 
					// api={urls['update_password']} 
					urls={{
						"token-email": urls["token-email"], 
						"update-password": urls["update-password"], 
					}}
				/>;
			
			default:
				return <h1 className="py-3 text-center">404 Not Found!</h1>;
		}
	}

	return getService(service);
}

/*
	[React Router]
		refer: https://reactrouter.com/en/main/upgrading/v5
*/
const App = () => {	
	const urls = getAPIUrls();

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home urls={urls} />} />
				<Route path="/:service" element={<Service urls={urls}/>} />
			</Routes>
		</BrowserRouter>
	)
};

export default App;