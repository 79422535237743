import { Table, Container, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
//  Custom Component
import Header from "./Header";
import LicenseInformation from "./LicenseInformation";

const Information = (props) => {
    const [main_cols, setMainCols] = useState([]);
    const [main_list, setMainList] = useState([]);
    const [master_tables, setMasterTables] = useState([]);
    const [master_table_cols, setMasterTableCols] = useState([]);
    const [master_table_data, setMasterTableData] = useState([]);
    const [status_list, setStatusList] = useState([]);
    // const [selected_license, setSelectedLicense] = useState("213c27d8");
    // const [detail_modal_show, setDetailModalShow] = useState(true);
    const [selected_license, setSelectedLicense] = useState("");
    const [detail_modal_show, setDetailModalShow] = useState(false);

    useEffect(() => {
        const url_status_list = props.urls["master-table"] + `?table_name=Status_List_tbl`;
        const url_app_main = props.urls["app-main"];
        const url_allow_master = props.urls["allow-master-list"];

        //  Status List
        fetch(url_status_list)
            .then(res => res.json())
            .then(json => {
                setStatusList(json)
            })
            .catch(err => {
                console.error(err);
                alert(`Unable request to "${url_status_list}"`);
            });
        
        //  Application List
        fetch(url_app_main)
            .then(res => res.json())
            .then(json => {
                setMainList(json);

                //  get all the "App_Main_tbl" property and add "Action"
                const cols = (json.length)? [...Object.keys(json[0]), "Action"]: [];

                if(json.length)
                    setMainCols(cols);
            })
            .catch(err => {
                console.error(err);
                alert(`Unable request to "${url_app_main}"`);
            });
        
        //  Allow access Master Table
        fetch(url_allow_master)
            .then(res => res.json())
            .then(json => setMasterTables(json))
            .catch(err => {
                console.error(err);
                alert(`Unable request to "${url_allow_master}"`);
            });
    }, [props.urls]);

    const selectedMasterTable = (ele) => {
        const { value } = ele.target;
        const url = props.urls["master-table"] + `?table_name=${value}`;

        fetch(url)
            .then(res => res.json())
            .then(json => {
                if(json.length){
                    let cols = Object.keys(json[0]);
                    
                    setMasterTableCols(cols);
                    setMasterTableData(json);
                }
            })
            .catch(err => {
                console.error(err);
                alert(`Unable request to "${url}"`);
            });
    }

    const checkPassword = (password) => {
        return new Promise((resolve, reject) => {
            //  get local cache token
            const json_str = JSON.stringify({
                token: localStorage.getItem("token") || "", 
                password: password, 
            });
            const url = props.urls['check-password'];
            const options = {
                method: "POST", 
                headers: { "Content-Type": "application/json" }, 
                body: json_str
            }

            fetch(url, options)
                .then(res => res.json())
                .then(json => {
                    console.log(`json: `);
                    console.log(json);

                    resolve(json);
                })
                .catch(err => {
                    console.log(err);
                    alert(`Unable request to "${url}"`);
                    reject(err);
                })
        });
    }

    const changeStatus = async(license_key, status_code) => {
        const password = prompt("Input Your Password: ");

        try{
            //  call "check-password" api, response
            const check_pass_res = await checkPassword(password);

            //  pass password
            if(check_pass_res.status_code === 1){
                const url = props.urls["update-status"];
                const options = {
                    method: "PUT", 
                    headers: { "Content-Type": "application/json" }, 
                    body: JSON.stringify({
                        token: localStorage.getItem("token") || "", 
                        license_key: license_key, 
                        status_code: status_code, 
                    })
                }

                fetch(url, options)
                    .then(res => res.json())
                    .then(json => alert(json.status_msg))
                    .catch(err => {
                        console.error(err);
                        alert(`Unable request to "${url}"`);
                    });
            }
            //  reset (status) main_list
            else{
                alert(`Password not pass!`);
                
                //  re-load page
                window.history.go(0);
            }
                
        }catch(err){
            console.log(err);
            console.log(`Fali to update status.`);
        }
    }

    return (
        <>
            <Header title={ props.title? props.title: "Information"} />
            <Container fluid={"md"} >
                <h3 className="my-3 text-center">Application List</h3>
                <Table variant="dark" className="text-center" striped bordered hover>
                    <thead>
                        <tr>
                            { main_cols.map(col => <td key={"th-cols-" + col}>{col}</td>) }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (main_list.length)?
                                main_list.map(row => <tr key={"tr-datas-" + row['License Key']}>
                                    { 
                                        main_cols.map((col, idx) => {
                                            let td_element;

                                            //  Status Code List of Application List
                                            if(col === "Status Code")
                                                td_element = <td className="p-1" key={`td-datas-${row['License Key']}-${idx}`}>
                                                    <select className="form-select" 
                                                        onChange={ele => {
                                                                const idx = ele.currentTarget.selectedIndex;
                                                                const status_code = status_list[idx]['Status Code'];

                                                                changeStatus(row['License Key'], status_code);
                                                            }}>
                                                        {
                                                            status_list.map((status, status_idx) => 
                                                                <option key={`app-${idx}-status-option-${status_idx}`} 
                                                                    value={status.status_code}
                                                                    selected={ row['Status Code'] === status['Status Code'] }
                                                                >
                                                                    {status['Description']}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                            else if(col === "Action")
                                                td_element = <td key={`td-datas-${row['License Key']}-${idx}`}>
                                                    <Button className="px-4"
                                                        variant={"outline-success"} 
                                                        onClick={() => {
                                                            // console.log(`selected license: ${row['License Key']}`);

                                                            setSelectedLicense(row['License Key']);
                                                            setDetailModalShow(true);
                                                        }}
                                                    >   
                                                        Edit
                                                    </Button>
                                                </td>;
                                            else
                                                td_element = <td key={`td-datas-${row['License Key']}-${idx}`}>{row[col]}</td>

                                            return td_element;
                                        })
                                    }
                                </tr>):
                                <tr>
                                    <td colSpan={6}> Data Empty </td>
                                </tr>
                        }
                    </tbody>
                </Table>
                <hr />
                <h3 className="my-3 text-center">Master Table</h3>
                <select className="form-select my-3"  style={{ padding: "0.75rem" }} 
                    onChange={selectedMasterTable}>
                    
                    <option>Select the Master Table</option>
                    {
                        master_tables.map(table => 
                            <option key={`master-table-${table}`} value={table}>
                                {table}
                            </option>)
                    }
                </select>
                { /* Master Table */ }
                <Table variant="dark" className="text-center" striped bordered hover>
                    <thead>
                        <tr>
                            { master_table_cols.map((col, idx) => <td key={`master-th-cols-${idx}`}>{col}</td>) }
                        </tr>
                    </thead>

                    <tbody>
                        {
                            //  build table
                            (master_table_data.length)?
                                //  build row
                                master_table_data.map((row, i) => 
                                    <tr key={`master-tr-datas-${i}`}>
                                    { 
                                        //  build col
                                        master_table_cols.map((col, j) => 
                                            (col === "License Key")?
                                                //  when click this column, will popup modal (LicenseInformation)
                                                <td 
                                                    key={`master-td-datas-${i}-${j}`} 
                                                    onClick={() => {
                                                        console.log(`In Master Table, selected license: ${row[col]}`);

                                                        setSelectedLicense(row[col]);
                                                        setDetailModalShow(true);
                                                    }}
                                                >
                                                    {row[col]}
                                                </td>
                                                :
                                                <td key={`master-td-datas-${i}-${j}`}>
                                                    {row[col]}
                                                </td>
                                        ) 
                                    }
                                    </tr>
                                ):
                                <tr>
                                    <td colSpan={6}> Data Empty </td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </Container>

            <LicenseInformation 
                license_key={ selected_license }
                show={ detail_modal_show }
                onHide={() => setDetailModalShow(false)} 
            />
        </>
    );
}

export default Information;