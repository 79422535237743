const domain = '34.126.87.87';
const port = 3000;
const apis = [
    //  not dity yet
    'app_list',
    'customers',
    'users',
    'areas',
    'jobs',
    'generate_license',
    'authorize',
    'authenticate',
    'reset_password',

    //  [POST] Method
    'token-email',
    'update-password',
    'check-password',
    'app-main-extend',
    'app-detail',
    //  [GET] Method
    'app-main',
    'allow-master-list',
    'master-table',
    //  [PUT] Method
    'update-status'
];

const getAPIUrls = (service = '') => {
    let urls = {};

    if (service === '') {
        for (let api of apis) urls[api] = `http://34.126.87.87:3000/${api}`;
        return urls;
    } else return `http://34.126.87.87:3000/${service}`;
};

const postRequest = ({ url = '', data = {} } = {}) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((json) => resolve(json))
            .catch((err) => {
                console.error(err);
                console.error(`[Post Request] Fail request to "${url}"`);

                reject(err);
            });
    });
};

/**
 *      [License Main Extend Information]
 *      App_Main_tbl join other table
 *
 * @param {string} license_key
 * @returns {Promise}
 */
const getLicenseMainExtend = async (license_key) => {
    const url = getAPIUrls('app-main-extend');

    //  if catch, resolve with empty "{default}" object
    return new Promise(async (resolve, reject) => {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: localStorage.getItem('token') || '',
                    license_key: license_key
                })
            };

            const response = await fetch(url, options);
            const json = await response.json();
            let result = {};

            //  rebuild properties name
            for (let prop in json[0]) {
                let new_prop = prop.toLowerCase().replace(/ /g, '_');
                result[new_prop] = json[0][prop];
            }

            resolve(result);
        } catch (err) {
            console.error(err);
            reject(`Unable request to "${url}"`);
        }
    });
};

/**
 *      [License Detail Information]
 *      App_Detail_tbl
 *
 * @param {string} license_key
 * @returns {Promise}
 */
const getLicenseDetail = async (license_key) => {
    const url = getAPIUrls('app-detail');

    return new Promise(async (resolve, reject) => {
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem('token') || ''
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') || '',
                    license_key: license_key
                })
            };

            const response = await fetch(url, options);
            const json = await response.json();
            let result = {};

            //  rebuild properties name
            for (let prop in json[0]) {
                let new_prop = prop.toLowerCase().replace(/ /g, '_');
                result[new_prop] = json[0][prop];
            }

            delete result['license_key'];
            resolve(result);
        } catch (err) {
            console.error(err);
            reject(`Unable request to "${url}"`);
        }
    });
};

/**
 *      [License Detail Information]
 *      App_Detail_tbl
 *
 * @param {string} license_key
 * @returns {Promise}
 */
const updateLicenseInformation = async (data = {}) => {
    console.log(`data: `);
    console.log(data);

    const url = getAPIUrls('update-license-information');
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((res) => res.json())
            .then((json) => resolve(json))
            .catch((err) => reject(err));
    });
};

const checkPassword = (token, password) => {
    const url = getAPIUrls('check-password');
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            token: token,
            password: password
        })
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((res) => res.json())
            .then((json) => resolve(json))
            .catch((err) => reject(err));
    });
};

export {
    getAPIUrls,
    postRequest,
    getLicenseMainExtend,
    getLicenseDetail,
    updateLicenseInformation,
    checkPassword
};
