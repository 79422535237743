import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
//	Custom Component
import Login from "./Login";
import GenerateLicense from "./GenerateLicense";

/*
	[Login Authentication]	

	refer: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
*/
const Home = (props) => {
	const getLocalToken = () => {
		let token = (localStorage.getItem("token") || "");
		return token;
	}

	//	ulrs of api
	const { urls } = props;
    //  get local token
	const [token, setToken] = useState(getLocalToken());
	//	is token passed verify
	const [is_pass, setIsPass] = useState(false);
	
	//	run once, use local token to verify
	useEffect(() => {
		const config = {
			method: "POST", 
			headers: {
				"Content-Type": "application/json"
			}, 
			body: JSON.stringify({ token: token })
		};

		fetch(urls.authenticate, config)
			.then(res => res.json())
			.then(json => {
				let { verify_code, status_msg } = json;

				if(verify_code === 1)
					setIsPass(true);
				else
                	localStorage.clear();
			});
	}, []);

	return (
		<Container>
			{
				is_pass?
					<GenerateLicense urls={urls} token={token} />:
					<Login urls={urls} />
			}
		</Container>
	)
};

export default Home;