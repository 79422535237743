import { useState } from 'react';
import {
    Toast,
    ToastContainer,
    Row,
    Col,
    FloatingLabel,
    Form,
    Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
    faCheck,
    faUser,
    faKey,
    faRightToBracket,
    faRotateRight
} from '@fortawesome/free-solid-svg-icons';

const Login = (props) => {
    const { urls } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    //  Toast Variable
    const [toast, setToast] = useState({
        show: false,
        icon: faSpinner,
        title: '',
        content: '',
        bg: 'light'
    });

    const loginVerify = () => {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        };

        fetch(urls['authorize'], options)
            .then((res) => res.json())
            .then((json) => {
                //  verified login
                if (json.status_code === 1) {
                    localStorage.setItem('token', json.token);
                    //  reload page
                    window.history.go(0);
                }
                //  fail to verify
                else {
                    alert(json.status_msg);
                    localStorage.clear();
                }
            })
            .catch((err) => {
                console.error(err);
                console.log(`Unable request to backend.`);
                alert(`Unable request to backend.`);
            });
    };

    const resetPassword = () => {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username
            })
        };

        setToast({
            ...toast,
            show: true,
            icon: faSpinner,
            title: 'Reset Your Password',
            content: 'Sending request to server.',
            bg: 'danger'
        });

        fetch(urls['reset_password'], options)
            .then((res) => res.json())
            .then((json) => {
                setToast({
                    ...toast,
                    show: true,
                    icon: faCheck,
                    title: 'Reset Your Password',
                    content: 'Successful.',
                    bg: 'success'
                });
            })
            .catch((err) => {
                console.log(err);

                setToast({
                    ...toast,
                    show: true,
                    title: 'Reset Your Password',
                    content: 'Unable request to backend.',
                    bg: 'danger'
                });
            });
    };

    return (
        <>
            <ToastContainer className="mt-5" position="top-center">
                <Toast
                    className="text-light"
                    bg={toast.bg}
                    show={toast.show}
                    onClose={() =>
                        setToast({
                            ...toast,
                            show: false
                        })
                    }
                >
                    <Toast.Header>
                        <FontAwesomeIcon className="me-2" icon={toast.icon} />
                        <strong className="me-auto">{toast.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{toast.content}</Toast.Body>
                </Toast>
            </ToastContainer>

            <div
                className="position-absolute top-50 start-50 translate-middle"
                style={{ minWidth: '300px' }}
            >
                <Row className="m-4">
                    <Col>
                        <FloatingLabel
                            controlId="input_username"
                            label={
                                <b>
                                    <FontAwesomeIcon
                                        className="me-2"
                                        icon={faUser}
                                    />
                                    Username
                                </b>
                            }
                        >
                            <Form.Control
                                type="text"
                                placeholder="Input Username"
                                onChange={(ele) =>
                                    setUsername(ele.target.value)
                                }
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="m-4">
                    <Col>
                        <FloatingLabel
                            controlId="input_password"
                            label={
                                <b>
                                    <FontAwesomeIcon
                                        className="me-2"
                                        icon={faKey}
                                    />
                                    Password
                                </b>
                            }
                        >
                            <Form.Control
                                type="password"
                                placeholder="Input Password"
                                onChange={(ele) =>
                                    setPassword(ele.target.value)
                                }
                            />
                        </FloatingLabel>

                        <Button variant="link" onClick={resetPassword}>
                            <FontAwesomeIcon
                                className="me-2"
                                icon={faRotateRight}
                            />
                            <small>Reset Password</small>
                        </Button>
                    </Col>
                </Row>

                <Row className="m-4">
                    <Col>
                        <Button
                            variant="btn d-flex m-auto px-5 btn-outline-success"
                            onClick={loginVerify}
                        >
                            <FontAwesomeIcon
                                className="align-self-center me-2"
                                icon={faRightToBracket}
                            />
                            <b>Login</b>
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Login;
