import { Navbar, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faEye, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { getAPIUrls } from './Api';
import { useEffect } from "react";

const Hearder = (props) => {
    //  check token is valid or not
    useEffect(() => {
        const token = localStorage.getItem("token") || "";
        const url = getAPIUrls("authenticate");
        let options = {
            method: "POST", 
            headers: { "Content-Type": "application/json" }, 
            body: JSON.stringify({ token: token })
        };
        
        fetch(url, options)
            .then(res => res.json())
            .then(json => {
                const { verify_code, status_msg, token } = json;

                if(verify_code !== 1){
                    alert(status_msg);
                    logout();
                }
                //  renew the token expire time
                else
                    localStorage.setItem("token", token);
            });
    }, []);

    const logout = () => {
        localStorage.removeItem("token");
        window.location.href = "/";
    }

    return (
        <>
            <Navbar>
                <Container>
                    { /* Home */ }
                    <Navbar.Brand href="/">
                        <Button variant="primary" className="px-4">
                            <FontAwesomeIcon className="me-2" icon={ faHouse } /> 
                            <b className="d-none d-md-inline">Home</b>
                        </Button>
                    </Navbar.Brand>
                    { /* Information */}
                    {
                        localStorage.getItem("token") && (<Navbar.Brand href="/information">
                            <Button className="px-4">
                                <FontAwesomeIcon className="me-2" icon={ faEye } /> 
                                <b className="d-none d-md-inline">Information</b>
                            </Button>
                        </Navbar.Brand>)
                    }
                    
                    { /* Logout */ }
                    {
                        localStorage.getItem("token") && (
                            <Navbar.Collapse className="justify-content-end">
                                <Button variant="danger" className="px-4" onClick={logout}>
                                    <FontAwesomeIcon className="me-2" icon={ faRightFromBracket } /> 
                                    <b className="d-none d-md-inline">Logout</b>
                                </Button>
                            </Navbar.Collapse>
                        )
                    }
                </Container>
            </Navbar>

            { /* if props.title not empty, return h3 elements with title */ }
            { (props.title && props.show) && <h3 className="py-3 text-center">{ props.title }</h3> }
        </>
    );
}

export default Hearder;