import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { 
    Modal, Row, Col, Collapse, 
    Form, Button, FloatingLabel, 

} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    //  Main Information
    faMobile, faReceipt, 
    faIdCard, faMapLocationDot, 
    faPen, faBriefcase, 

    //  Detail Information
    faEye, faEyeSlash, 
} from '@fortawesome/free-solid-svg-icons';
//  Custom Component
import { 
    getAPIUrls, postRequest, 
    getLicenseMainExtend, getLicenseDetail, 
    updateLicenseInformation, checkPassword, 
} from "./Api";


const ContextDetail = createContext({
    detail: {}, 
    setDetail: () => {}, 
});

const LicenseDetail = () => {
    const { detail, setDetail } = useContext(ContextDetail);
    const columns = Object.keys(detail);

    return (
        <Row>
            <h5 className="mb-3 text-center">
                <u>{ `Detail Information` }</u>
            </h5>
            {
                //  Map to generate columns
                columns.map(col => {
                    let col_name = col.split("_").map(s => s[0].toUpperCase() + s.substring(1)).join(" ");
                    let id = col.toLowerCase().replace(/ /g, "_");
                    let key = `detail-${col}`;
                    
                    return (
                        <Col className="mb-3"
                            key={key} md={6}> 
                            <FloatingLabel controlId={id}
                                label={<b>{ col_name }</b>}
                            >
                                <Form.Control 
                                    type="text" placeholder={ col } 

                                    value={ detail[col] }
                                    onChange={ele => {
                                        let _new_detail = {
                                            ...detail, 
                                        };
                                        _new_detail[col] = ele.target.value;

                                        setDetail(_new_detail);
                                    }}

                                />
                            </FloatingLabel>
                        </Col>
                    )
                })
            }
        </Row>
    );
}

const ModalContent = ({ license_key, update, closeModal }) => {
    //  Data List
    const [app_list, setAppList] = useState([]);
    const [status_list, setStatusList] = useState([]);
    const [user_list, setUserList] = useState([]);
    const [area_list, setAreaList] = useState([]);
    const [job_list, setJobList] = useState([]);
    
    //  Main Information
    const [main, setMain] = useState(null);
    //  Detail Information
    const [open_detail, setOpenDetail] = useState(false);
    const [detail, setDetail] = useState(null);

    //  Log Information
    const [open_object, setOpenObject] = useState(false);

    //  Inject "detail" value for "ContextDetail"
    const context_detail_value = useMemo(() => 
        ({detail, setDetail})
    , [detail, setDetail]);
    

    useEffect(() => {
        postRequest({ url: getAPIUrls('app_list') })
            .then(data => setAppList(data))
            .catch(err => console.log(err));

        fetch(getAPIUrls('master-table') + `?table_name=Status_List_tbl`)
            .then(res => res.json())
            .then(json => setStatusList(json))
            .catch(err => console.log(err));

        postRequest({ url: getAPIUrls('users') })
            .then(data => setUserList(data))
            .catch(err => console.log(err));

        postRequest({ url: getAPIUrls('areas') })
            .then(data => setAreaList(data))
            .catch(err => console.log(err));

        postRequest({ url: getAPIUrls('jobs') })
            .then(data => setJobList(data))
            .catch(err => console.log(err));
        
        if(license_key){
            //  get main extend information
            getLicenseMainExtend(license_key)
                .then(json => setMain(json))
                .catch(err => alert(err));

            //  get detail information
            getLicenseDetail(license_key)
                .then(json => setDetail(json))
                .catch(err => alert(err));
        }
            
    }, [license_key]);

    return (
    <Form>
        <Row className="mb-3">
            <Col md={12} className="text-center">
                <h5><u>{ main && main.customer_name }</u></h5>
            </Col>
        </Row>
        { /* App */ }
        <Row className="mb-3">
            { /* Application */ }
            <Col md={6}>
                <FloatingLabel controlId="app_code"
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faMobile} />Application
                        <span className="ms-1 text-danger">*</span>
                    </b>}
                >
                    <Form.Select
                        onChange={ele => {
                            let code = ele.target.value;
                            let name = ele.target.selectedOptions[0].text;

                            setMain({
                                ...main, 
                                app_code: code, 
                                app_name: name, 
                            });
                        }}
                    >
                        {
                            app_list.map((app, idx) => 
                                <option 
                                    key={`app-${idx}`} value={app['App Code']}
                                    selected={ main && main.app_code === app['App Code'] }
                                >
                                    { app['App Name'] }
                                </option>
                            ) 
                        }
                    </Form.Select>
                </FloatingLabel>
            </Col>
            { /* Status */ }
            <Col md={6}>
                <FloatingLabel controlId="status_code"
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faReceipt} />Status
                        <span className="ms-1 text-danger">*</span>
                    </b>}
                >
                    <Form.Select
                        onChange={ele => {
                            let code = ele.target.value;
                            let name = ele.target.selectedOptions[0].text;

                            setMain({
                                ...main, 
                                status_code: code, 
                                status_description: name, 
                            });
                        }}
                    >
                        {
                            status_list.map((status, idx) =>
                                <option
                                    key={`status-${idx}`} value={status['Status Code']}
                                    selected={ main && main.status_code === status['Status Code'] }
                                >
                                    { status['Description'] }
                                </option>
                            )
                        }
                    </Form.Select>
                </FloatingLabel>
            </Col>
        </Row>
        { /* User */ }
        <Row className="mb-3">
            { /* User By */ }
            <Col md={6}>
                <FloatingLabel controlId="used_by" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faIdCard} />Used By
                        <span className="ms-1 text-danger">*</span>
                    </b>}
                >
                    <Form.Control type="text" placeholder="Used By" 
                        defaultValue={ main? main.used_by: "" } list="user_list" 
                        
                        onChange={ele => {
                            let new_user_code = ele.target.value;
                            //  if the input code exist, just load the name
                            let exist = user_list.filter(user => 
                                user['Customer Code'] === main.customer_code
                                    &&
                                user['User Code'] === new_user_code
                            );
                            let name = exist.length? exist[0]['User Name']: "";

                            setMain({
                                ...main, 
                                used_by: ele.target.value, 
                                user_name: name, 
                            });
                        }} 
                    />
                </FloatingLabel>
                <datalist id="user_list">
                    {
                        //  filter with specify customer 
                        //  (according to the customer code input value)
                        user_list.filter(
                                user => user['Customer Code'] === (main? main.customer_code: "")
                            )
                            .map((user, idx) => 
                                <option key={`user-${idx}`} value={user['User Code']}>{ user['User Name'] }</option>
                            )
                    }
                </datalist>
            </Col>
            { /* User Name */ }
            <Col md={6}>
                <FloatingLabel controlId="user_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faIdCard} />User Name</b>}>
                    <Form.Control type="text" placeholder="User Name" 
                        defaultValue={main? main.user_name: ""} 
                        onChange={ele => setMain({
                            ...main, 
                            user_name: ele.target.value, 
                        })}
                        disabled={ 
                            user_list.filter(
                                    user => user['Customer Code'] === (main? main.customer_code: "")
                                )
                                .map(obj => obj['Used By'])
                                .includes(main? main.user_code: null)
                        }
                    />
                </FloatingLabel>
            </Col>
        </Row>
        { /* Area */ }
        <Row className="mb-3">
            { /* Area Code */ }
            <Col md={6}>
                <FloatingLabel controlId="area_code" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />Area Code
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input Area" list="area_list" 
                        defaultValue={ main? main.area_code: "" }  
                        onChange={ele => {
                            let new_area_code = ele.target.value;
                            //  if the input code exist, just load the name
                            let exist = area_list.filter(area => area['Area Code'] === new_area_code);
                            let name = exist.length? exist[0]['Area Name']: "";

                            setMain({
                                ...main, 
                                area_code: new_area_code, 
                                area_name: name, 
                            });
                        }} 
                    />
                </FloatingLabel>
                <datalist id="area_list">
                    {
                        area_list.map((area, idx) => 
                            <option key={`area-${idx}`} value={area['Area Code']}>{ area['Area Name'] }</option>
                        )
                    }
                </datalist>
            </Col>
            { /* Area Name */ }
            <Col md={6}>
            <FloatingLabel controlId="area_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faMapLocationDot} />Area Name</b>}>
                    <Form.Control type="text" placeholder="Area Name" disabled 
                        defaultValue={ main? main.area_name: "" } />
                </FloatingLabel>
            </Col>
        </Row>
        { /* Job */ }
        <Row className="mb-3">
            { /* Job Code */ }
            <Col md={6}>
                <FloatingLabel controlId="job_position"
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faBriefcase} />Job Position
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input Job" list="job_list" 
                        value={ main? main.job_position: "" }
                        onChange={ele => {
                            let new_job_code = ele.target.value;
                            //  if the input code exist, just load the name
                            let exist = job_list.filter(job => job['Job Code'] === new_job_code);
                            let name = exist.length? exist[0]['Job Name']: "";

                            setMain({
                                ...main, 
                                job_position: new_job_code, 
                                job_name: name, 
                            });
                        }}
                    />
                </FloatingLabel>
                <datalist id="job_list">
                    {
                        job_list.map((job, idx) => 
                            <option key={`job-${idx}`} value={job['Job Code']}>{ job['Job Name'] }</option>
                        )
                    }
                </datalist>
            </Col>
            { /* Job Name */ }
            <Col md={6}>
                <FloatingLabel controlId="job_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faBriefcase} />Job Name</b>}>
                    <Form.Control type="text" placeholder="Job Name" disabled 
                        value={ main? main.job_name: "" } />
                </FloatingLabel>
            </Col>
        </Row>

        { /* Remark */ }
        <Row className="mb-3">
            <Col>
                <FloatingLabel controlId="remark" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faPen} />Remark</b>}>
                    
                    <Form.Control style={{minHeight: "7rem"}} as="textarea" rows={3} placeholder="Input Remark" 
                        value={ detail !== null? detail.remark: "" } onChange={ele => setDetail({
                            ...detail, 
                            remark: ele.target.value
                        })} />
                </FloatingLabel>
            </Col>
        </Row>

        { /* Detail Inforamtion */ }
        <Row className="mb-3">
            <hr />
            <Col md={12} className="d-flex m-auto mb-3">
                <Button 
                    className="m-auto px-4" 
                    variant="success" 
                    onClick={() => setOpenDetail(!open_detail)} 
                    aria-controls="detail-information" 
                    aria-expanded={open_detail} 
                >
                    <span className="me-2">
                        { open_detail?  
                            <FontAwesomeIcon icon={ faEyeSlash } />:
                            <FontAwesomeIcon icon={ faEye } />
                        }
                    </span>
                    <span>
                        {
                            `${open_detail? "Hide": "Show"} Detail` 
                        }
                    </span>
                </Button>
            </Col>
            {
                /* Collapse - LicenseDetail */
            }
            <Col md={12}>
                <Collapse in={open_detail}>
                <div id="detail-information">
                    <ContextDetail.Provider value={context_detail_value}>
                        {
                            detail && <LicenseDetail />
                        }
                    </ContextDetail.Provider>
                </div>
                </Collapse>
            </Col>
            <hr />
        </Row>

        { /* Save Button */ }
        <Row>
            <Col className="d-flex m-auto">
                <Button className="m-auto px-5"
                    onClick={async() => {
                        const token = localStorage.getItem("token") || "";
                        const password = prompt("Input Your Password: ", "");
                        const pass_res = await checkPassword(token, password);
                        
                        if(pass_res.status_code === 1)
                            update({
                                license_key: license_key, 
                                main: {...main}, 
                                detail: {...detail}, 
                            })
                            .then(res => {
                                const { status_code, status_msg } = res;
                                
                                alert(status_msg);
                                closeModal();
                            });
                            else
                                alert(`Password not pass!`);
                    }}
                >
                    Save
                </Button>
            </Col>
        </Row>

        { /* Other */ 
        <Row className="mb-3">
            <Col md={12}>
                <Button variant="outline-warning" 
                    onClick={() => setOpenObject(!open_object)}
                    aria-controls="log_information" 
                    aria-expanded={open_object} 
                >
                    Show shared variable object
                </Button>
            </Col>
            
            <Collapse in={open_object}>
                <div id ="log_information">
                <Col>
                    <pre>
                        { 
                            JSON.stringify({
                                main: {...main}, 
                                detail: {...detail}, 
                            }, null, 2) 
                        }
                    </pre>
                </Col>
                <Col className="m-auto text-center">
                    {/* <Button onClick={() => setInput(getInput())} >Update Input</Button> */}
                </Col>
                </div>
            </Collapse>
        </Row>
        }
    </Form>);
}

const LicenseInformation = ({ license_key, show, onHide }) => {
    return (
        <Modal show={show} size="lg" centered
            aria-labelledby="modal-license-detail">
            <Modal.Header>
                <Modal.Title id="modal-license-detail">
                    <h4>Information of License Key "{license_key}"</h4>
                </Modal.Title>
                { /* close button */ }
                <div
                    style={{ cursor: "pointer" }} 
                    className="m-0 px-2 fs-4" 
                    onClick={onHide} 
                >
                    X
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight: 300 }}>
                <ModalContent 
                    license_key={license_key}
                    update={updateLicenseInformation}
                    closeModal={onHide}
                />
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="outline-danger" className="m-auto my-2 px-5" onClick={onHide}>
                    Close
                </Button>
                <Button variant="outline-primary" className="m-auto my-2 px-5">
                    Save
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default LicenseInformation;