import {Row, Col, FloatingLabel, Form, Button} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faMobile, faIdCard, faUsers, 
    faMapLocationDot, faFileImport, faKey, 
    faPen, faMessage, faBriefcase 
} from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
//  Custom Component
import Header from "./Header";
import { postRequest } from "./Api";

const GenerateLicense = (props) => {
    const urls = props.urls;
    const is_invalid = "is-invalid";
    
    //  Data List of Master Table
    const [app_list, setAppList] = useState([]);
    const [customer_list, setCustomerList] = useState([]);
    const [user_list, setUserList] = useState([]);
    const [area_list, setAreaList] = useState([]);
    const [job_list, setJobList] = useState([]);

    //  Data of Input
    const [input_app, setInputApp] = useState("");
    const [input_user, setInputUser] = useState("");
    const [input_customer, setInputCustomer] = useState("");
    const [input_area, setInputArea] = useState("");
    const [input_job, setInputJob] = useState("");
    const [input_remark, setInputRemark] = useState("");
    //  check input 
    const [disable_submit, setDisableSubmit] = useState(true);

    //  selected app (name)
    const [selected_app, setSelectedApp] = useState("");
    //  selected customer-user
    const [selected_user, setSelectedUser] = useState({
        customer_name: "", 
        user_name: "", 
        area_name: "", 
        job_name: ""
    });

    //  Information of License
    const [license, setLicense] = useState({
        "license_key": "", 
        "active_code": "", 
        "time": 0,
        "status_msg":""
    });

    //  run once, request to get master table data for input 
    //  (app_list, customer, user, area, job)
    useEffect(() => {
        postRequest({ url: urls['app_list'] })
            .then(data => setAppList(data))
            .catch(err => console.log(err));

        postRequest({ url: urls['customers'] })
            .then(data => setCustomerList(data))
            .catch(err => console.log(err));

        postRequest({ url: urls['users'] })
            .then(data => setUserList(data))
            .catch(err => console.log(err));

        postRequest({ url: urls['areas'] })
            .then(data => setAreaList(data))
            .catch(err => console.log(err));

        postRequest({ url: urls['jobs'] })
            .then(data => setJobList(data))
            .catch(err => console.log(err));
    }, [urls]);

    const generate = async() => {
        if(checkInput())
            return alert(`Some field not fill in yet.`);

        const config = {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            }, 
            
            body: JSON.stringify({
                token: localStorage.getItem("token") || "", 
                ...getInputData(), 
            })
        }
        
        fetch(urls['generate_license'], config)
            .then(response => response.json())
            .then(json => {
                //  successful generate, time will more than 0 
                if(json.time){
                    setLicense(() => json);
                    copyToClipboard(json.license_key);
                }
                else{
                    alert(json.status_msg);
                    localStorage.removeItem("token");
                    window.history.go(0);
                }
            })
            .catch(err => {
                console.error(err);
                console.log(`Unable to request "${urls['generate_license']}"`);
            });
    }

    const copyToClipboard = (text) => {
        //  avoid http can't use navigator.clipboard (create textarea element)
        //  refer: https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext
        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

        if(window.isSecureContext && navigator.clipboard)
            //  refer: https://medium.com/nerd-for-tech/how-to-add-copy-to-clipboard-functionality-in-a-reactjs-app-45404413fdb2
            navigator.clipboard.writeText(text);
        else
            unsecuredCopyToClipboard(text);
      };
    
    const selectedUser = (e) => {
        let { value } = e.target;

        setInputUser(value);
        //  clear the user name
        setSelectedUser({
            ...selected_user, 
            user_name: "", 
        });
        
        //  if user exist in the user list, auto fill in related value
        for(let user of user_list)
            if(user['Customer Code'] === input_customer && user['User Code'] === value){
                setInputArea(user['Area Code']);
                setInputJob(user['Job Position']);
                
                setSelectedUser({
                    customer_name: user['Customer Name'], 
                    user_name: user['User Name'], 
                    area_name: user['Area Name'], 
                    job_name: user['Job Name'], 
                });

                break;
            }
        //  filled required field
        setDisableSubmit(checkInput());
    }

    const getInputData = () => ({
        //  for email record the generate license was what app name 
        app_name: selected_app, 
        app_code: input_app, 
        customer_code: input_customer, 
        user_by: input_user, 
        //  for generate or refer user
        user_name: selected_user.user_name, 
        area_code: input_area, 
        job_position: input_job, 
        remark: input_remark, 
    });

    //  all already keyin data (not empty)
    const checkInput = () => {
        let input_list = [input_app, input_customer, input_user, input_area, input_job];
        let flag_input = false;
        
        for(let input of input_list)
            if(input === ""){
                flag_input = true;
                break;
            }
        
        return flag_input;
    }

    const handleChange = (ele) => {
        const { id, value } = ele.target;
        let input_objs = {
            "input_customer": (code) => {
                setInputCustomer(code);
                setSelectedUser({
                    ...selected_user, 
                    customer_name: customer_list.filter(customer => 
                        customer['Customer Code'] === code
                    )[0]['Customer Name'], 
                });
            }, 
            
            "input_user": code => setInputUser(code), 

            "input_area": (code) => {
                setInputArea(code);
                setSelectedUser({
                    ...selected_user, 
                    area_name: area_list.filter(area => 
                        area['Area Code'] === code
                    )[0]['Area Name'], 
                });
            }, 
            "input_job": (code) => {
                setInputJob(code);
                setSelectedUser({
                    ...selected_user, 
                    job_name: job_list.filter(job => 
                        job['Job Code'] === code
                    )[0]['Job Name'], 
                });
            },
        }
        
        input_objs[id](value);
        setDisableSubmit(checkInput());
    }
    
    /*
        Custom Component
    */
    const InvalidFeedback = ({ message }) => (
        <div className="invalid-feedback">{message}</div>
    )

    return (<>
        <Header title="Generate License" show={true} />
        <Row className="mt-3 mb-3">
            { /* Select App */}
            <Col md={6} className="m-auto">
                <FloatingLabel controlId="input_app"
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faMobile} />Application
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Select 
                        className={!input_app && is_invalid} 
                        onChange={ele => {
                            let code = ele.target.value;
                            let name = ele.target.selectedOptions[0].text;
                            
                            setInputApp(code);
                            setSelectedApp(name);
                        }}
                    >
                        <option selected disabled>Select The Application</option>
                        {
                            app_list.map((app, idx) => 
                                <option key={`app-${idx}`} value={app['App Code']}>{ app['App Name'] }</option>
                            ) 
                        }
                    </Form.Select>
                    {
                        (!input_app) &&
                            <InvalidFeedback message="Please select an application." />
                    }
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="mb-3">
            { /* Input Customer */ }
            <Col md={6}>
                <FloatingLabel controlId="input_customer" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faUsers} />Customer Code
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input Custoemr" list="customer_list" 
                        className={!input_customer && is_invalid} 
                        value={input_customer} 
                        onChange={handleChange} 
                    />
                    {
                        (!input_customer) &&
                            <InvalidFeedback message="Please select a customer code." />
                    }    
                </FloatingLabel>
                <datalist id={"customer_list"}>
                    {
                        customer_list.map((customer, idx) => 
                            <option key={`customer-${idx}`} value={customer['Customer Code']}>{ customer['Customer Name'] }</option>
                        )
                    }
                </datalist>
            </Col>
            { /* Customer Name */ }
            <Col md={6}>
                <FloatingLabel controlId="customer_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faUsers} />Customer Name</b>}>
                    <Form.Control type="text" placeholder="Customer Name" disabled 
                        value={ selected_user.customer_name } />
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="mb-3">
            { /* Input User */ }
            <Col md={6}>
                <FloatingLabel controlId="input_user" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faIdCard} />User By
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input User" list="user_list" 
                        className={!input_user && is_invalid}
                        value={input_user} 
                        onChange={selectedUser} 
                    />
                    {
                        (!input_user) && 
                            <InvalidFeedback message="Please select a user code." />
                    }
                </FloatingLabel>
                <datalist id={"user_list"}>
                    {
                        //  filter with specify customer 
                        //  (according to the customer code input value)
                        user_list.filter(user => user['Customer Code'] === input_customer)
                            .map((user, idx) => 
                                <option key={`user-${idx}`} value={user['User Code']}>{ user['User Name'] }</option>
                            )
                    }
                </datalist>
            </Col>
            { /* User Name */ }
            <Col md={6}>
                <FloatingLabel controlId="user_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faIdCard} />User Name</b>}>
                    <Form.Control type="text" placeholder="User Name" 
                        value={ selected_user.user_name }
                        disabled={ 
                            //  selected exist customer-user
                            user_list.filter(user => user['Customer Code'] === input_customer)
                                .map(obj => obj['User Code'])
                                .includes(input_user)
                        }
                        onChange={ele => 
                            setSelectedUser({
                                ...selected_user, 
                                user_name: ele.target.value, 
                            })
                        }
                    />
                </FloatingLabel>
            </Col>
        </Row> 
        <Row className="mb-3">
            { /* Input Area */ }
            <Col md={6}>
                <FloatingLabel controlId="input_area" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />Area Code
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input Area" list="area_list" 
                        className={!input_area && is_invalid}
                        value={input_area} 
                        onChange={handleChange} 
                    />
                    {
                        (!input_area) &&
                            <InvalidFeedback message={"Please select a area code."} />
                    }
                </FloatingLabel>
                <datalist id={"area_list"}>
                    {
                        area_list.map((area, idx) => 
                            <option key={`area-${idx}`} value={area['Area Code']}>{ area['Area Name'] }</option>
                        )
                    }
                </datalist>
            </Col>
            { /* Area Name */ }
            <Col md={6}>
                <FloatingLabel controlId="area_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faMapLocationDot} />Area Name</b>}>
                    <Form.Control type="text" placeholder="Area Name" disabled 
                        value={ selected_user.area_name } />
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="mb-3">
            { /* Input Job */ }
            <Col md={6}>
                <FloatingLabel controlId="input_job" 
                    label={<b>
                        <FontAwesomeIcon className="me-2" icon={faBriefcase} />Job Position
                        <span className="ms-1 text-danger">*</span>
                    </b>}>
                    
                    <Form.Control type="text" placeholder="Input Job" list="job_list" 
                        className={!input_job && is_invalid}
                        value={input_job} 
                        onChange={handleChange} 
                    />
                    {
                        (!input_job) &&
                            <InvalidFeedback message={"Please select a job position."} />
                    }
                </FloatingLabel>
                <datalist id={"job_list"}>
                    {
                        job_list.map((job, idx) => 
                            <option key={`job-${idx}`} value={job['Job Code']}>{ job['Job Name'] }</option>
                        )
                    }
                </datalist>
            </Col>
            { /* Job Name */ }
            <Col md={6}>
                <FloatingLabel controlId="job_name" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faBriefcase} />Job Name</b>}>
                    <Form.Control type="text" placeholder="Job Name" disabled 
                        value={ selected_user.job_name } />
                </FloatingLabel>
            </Col>
        </Row>
        <Row>
            { /* Input Remark */ }
            <Col>
                <FloatingLabel controlId="input_remark" 
                    label={<b><FontAwesomeIcon className="me-2" icon={faPen} />Remark</b>}>
                    
                    <Form.Control style={{minHeight: "7rem"}} as="textarea" rows={3} placeholder="Input Remark" 
                        value={input_remark} onChange={ele => setInputRemark(ele.target.value)} />
                </FloatingLabel>
            </Col>
        </Row> 
        <Row className="my-5">
            <Col>
                <Button className="d-flex m-auto px-5 align-items-center" variant="outline-success" 
                    onClick={generate} 
                    // disabled={disable_submit}
                >
                    <FontAwesomeIcon className="me-2" icon={faFileImport} />
                    <b>Generate License</b>
                </Button>
            </Col>
        </Row> 
        { /* Information of License Key */ }
        <Row className="m-auto pb-3">
            <Col md={12} className="mb-3 text-center">
                <p><b>
                    <FontAwesomeIcon className="me-2" icon={faKey} />
                    License Key: <span className="ms-3">{license.license_key}</span>

                    { /* copy "license_key" to clipboard */ }
                    <Button className="ms-5 px-4" style={{ position: "absolute" }} variant="outline-primary" onClick={(current) => {
                        copyToClipboard(license.license_key);
                        current.target.innerText = "Copied";

                        setTimeout(() => current.target.innerText = "Copy", 3000);
                    }}>Copy</Button>
                </b></p>
            </Col>
        </Row>
        <Row className="m-auto pb-3">
            <Col md={12} className="mb-3 text-center">
                <p><b>
                    <FontAwesomeIcon className="me-2" icon={faMessage} />
                    Status Message: <span className="ms-3">{license.status_msg}</span>
                </b></p>
            </Col>
        </Row>
    </>)
}

export default GenerateLicense;